import { UiService } from './../../services/ui.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalConstants } from '../shared/GlobalConstans';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.css']
})
export class TopBarComponent implements OnInit {

  constructor(private router: Router, private uiService: UiService) { }

  ngOnInit() {
  }

  logout() {
    //GlobalConstants.user = undefined;
    this.router.navigateByUrl("/login");
  }

  toogleSidebar() {
    GlobalConstants.showSidebar = GlobalConstants.showSidebar ? false : true;
    this.uiService.setshowSidebar(GlobalConstants.showSidebar);
  }

}
