import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { GlobalConstants } from '../common/shared/GlobalConstans';

@Injectable({
  providedIn: 'root'
})
export class UiService {

  private showSidebar: BehaviorSubject<boolean>;
  userRole = GlobalConstants.user.type;

  constructor() {
    this.showSidebar = new BehaviorSubject<boolean>(true);
  }

  getshowSidebar(): Observable<boolean> {
    return this.showSidebar.asObservable();
  }
  setshowSidebar(newValue): void {
    this.showSidebar.next(newValue);
  }

  showButtonByRole(role: string[]) {
    if (this.userRole.filter(x => role.includes(x)).length > 0)
      return true;
    return false;
  }
}
