import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { GlobalConstants } from 'src/app/common/shared/GlobalConstans';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {


  //Inicializar
  user = "";
  pass = "";

  constructor(private router: Router, private db: AngularFirestore) { }

  ngOnInit() {
  }

  async login() {
    let login = {
      user: this.user,
      password: this.pass
    }
    let existUser = await this.validateUser(login);
    // console.log(login);
    // console.log(existUser);
    if (existUser.length == 0) {
      alert("Incorrect user or password");
      return;
    }
    GlobalConstants.user = existUser[0].data();
    GlobalConstants.user.id = existUser[0].id;
    this.router.navigate(['/app/home']);
  }

  async validateUser(login) {
    return await (await this.db.collection('User').ref.where('user', '==', login.user).where('password', '==', login.password).get()).docs;
  }

}
