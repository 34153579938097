import { UiService } from './../../services/ui.service';
import { Component, OnInit } from '@angular/core';
import { GlobalConstants } from '../shared/GlobalConstans';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.css']
})
export class SideMenuComponent implements OnInit {

  userRole = GlobalConstants.user.type;
  userId = GlobalConstants.user.id;

  showSidebar = true;
  constructor(private uiService: UiService) { }

  ngOnInit() {
    this.uiService.getshowSidebar().subscribe(value => {
      this.showSidebar = value;
    });
  }

  showMenu(role: string[]) {
    if (this.userRole.filter(x => role.includes(x)).length > 0)
      return true;

    return false;
  }

}
