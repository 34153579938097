import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import * as moment from 'moment';
import readXlsxFile from 'read-excel-file'
import { throwError } from 'rxjs';

@Component({
  selector: 'app-import-blulk-proyects',
  templateUrl: './import-blulk-proyects.component.html',
  styleUrls: ['./import-blulk-proyects.component.css']
})
export class ImportBlulkProyectsComponent implements OnInit {

  public loading = false;
  blulkData = [];
  file: any;
  data: any;
  count = 0;
  fileReaded = false;
  meterialsWithErrors = [];

  constructor(public db: AngularFirestore,
    private router: Router,) { }

  ngOnInit() {
  }

  fileChanged(e) {
    this.file = e.target.files[0];
    this.meterialsWithErrors = [];
  }

  readFile() {
    if (!confirm("Are you sure of read this file?")) return;
    this.loading = true;
    this.blulkData = [];
    try {
      readXlsxFile(this.file, { getSheets: true }).then((sheets) => {
        for (let i = 0; i < sheets.length; i++) {
          readXlsxFile(this.file, { sheet: sheets[i].name }).then(async (rows) => {
            this.data = rows;
            await this.readProject(rows)
            this.loading = false;
          }, errors => {
            console.log(errors);
            alert("Error reading file");
            this.loading = false;
          });
        }
      });


    } catch (error) {
      console.log(error);
      alert("Error reading file");
      this.loading = false;
      return;
    }
  }

  async readProject(rows) {
    try {
      let newItem: any = {};
      //Get general data
      for (let i = 1; i < 2; i++) {
        newItem.name = rows[i][0];
        newItem.initDate = moment(rows[i][1], "DD/MM/YYYY").format("YYYY/MM/DD");
        newItem.phone = rows[i][2];
        newItem.budget = 0;
        newItem.address = rows[i][3];
        newItem.taxPercent = parseInt(rows[i][5].trim());
        newItem.supplyers = [];
        let suppliers: string[] = rows[i][4].split("/");
        for (let i = 0; i < suppliers.length; i++) {
          let supplier = await (await this.db.collection("Suppliers").ref.where("name", "==", suppliers[i].trim().toUpperCase()).get()).docs;
          if (supplier.length > 0)
            newItem.supplyers.push({ ...supplier[0].data(), id: supplier[0].id });
          else
            throwError("No se puede leer");
        }
      }

      newItem.scopes = [];

      //Get material data
      for (let i = 4; i < rows.length; i++) {
        let scopeName = rows[i][0];
        console.log(scopeName);
        let scopes = await (await this.db.collection("Scopes").ref.where("name", "==", scopeName.trim().toUpperCase()).get()).docs;
        if (scopes.length > 0) {
          let fscope = scopes[0].data();
          fscope.id = scopes[0].id;
          fscope.budget = 0;
          fscope.materials = [];
          let validate = true;

          for (let j = i + 1; validate == true; j++) {
            if (j >= rows.length) {
              validate = false;
              i = j;
              break;
            }
            if (rows[j][1] != null) {
              let newMat = rows[j][1].trim();
              let quantity = rows[j][9];
              let price = rows[j][10];
              let materials = await (await this.db.collection("Materials").ref.where("name", "==", newMat.trim().toUpperCase()).get()).docs;
              if (materials.length > 0) {
                try {
                  let fmaterial = materials[0].data();
                  fmaterial.id = materials[0].id;
                  fmaterial.itemName = materials[0].data().name;
                  fmaterial.quantity = parseFloat(quantity);
                  fmaterial.price = parseFloat(price);
                  fmaterial.total = quantity * fmaterial.price;
                  fmaterial.total = this.round(fmaterial.total);
                  if (isNaN(fmaterial.total)) {
                    console.log(newMat);
                    this.meterialsWithErrors.push(newMat);
                    throwError("No se puede leer");
                  }
                  newItem.budget = newItem.budget + fmaterial.total;
                  fscope.budget = fscope.budget + fmaterial.total;
                  fscope.materials.push(fmaterial);
                } catch (e) {
                  this.meterialsWithErrors.push(newMat);
                  console.log(newMat)
                }
              }
              else {
                try {
                  //console.log(newMat.trim().toUpperCase())
                  let fmaterial: any = {};
                  fmaterial.itemName = newMat.trim().toUpperCase();
                  fmaterial.name = newMat.trim().toUpperCase();
                  fmaterial.quantity = parseFloat(quantity);
                  fmaterial.price = parseFloat(price);
                  fmaterial.total = quantity * fmaterial.price;
                  fmaterial.total = this.round(fmaterial.total);
                  if (fmaterial.total == NaN) {
                    console.log(newMat);
                    throwError("No se puede leer");
                  }
                  let mat = await (await this.db.collection("Materials").add(fmaterial)).get();
                  fmaterial.id = mat.id;
                  //fmaterial.id = "asdas";
                  newItem.budget = newItem.budget + fmaterial.total;
                  fscope.budget = fscope.budget + fmaterial.total;
                  fscope.materials.push(fmaterial);
                  //throwError("No se puede leer");
                }
                catch (e) {
                  this.meterialsWithErrors.push(newMat);
                  console.log(newMat)
                }
              }
            }
            else {
              validate = false;
              i = j - 1;
            }
          }
          newItem.scopes.push(fscope);
        }
        else
          throwError("No se puede leer");
      }

      this.blulkData.push(newItem);

    } catch (error) {
      console.log(error);
      alert("Error reading file");
      this.fileReaded = false;
      return;
    }
    this.count = this.blulkData.length;
    this.fileReaded = true;
    if (this.meterialsWithErrors.length > 0) this.fileReaded = false;
    console.log(this.blulkData)
  }

  round(num) {
    var m = Number((Math.abs(num) * 100).toPrecision(15));
    return Math.round(m) / 100 * Math.sign(num);
  }

  async saveFile() {
    if (!confirm("Are you sure of save this data?")) return;
    this.loading = true;
    for (let i = 0; i < this.blulkData.length; i++) {
      await this.db.collection("Projects").add(this.blulkData[i]);
    }
    this.loading = false;
    alert("Data saved");
    this.router.navigate(['/app/proyects']);
  }

}
