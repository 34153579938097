import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-create-proyect',
  templateUrl: './create-proyect.component.html',
  styleUrls: ['./create-proyect.component.css']
})
export class CreateProyectComponent implements OnInit {

  item = {
    price: 0,
    name: "",
    phone: "",
    budget: 0,
    address: "",
    scopes: [],
    supplyers: [],
    taxPercent: 0,
    initDate: moment().format("YYYY/MM/DD")
  }

  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  scopeBudget = 0;
  scopeList: any = [];
  materialsList: any = [];
  supplyersList: any = [];
  selectedScope: any;
  selectedMaterial: any;
  selectedSupplier: any;
  currentScope: any;
  scopeIndex = 0;
  price = 0;
  quantity = 0;
  urlSafe: SafeResourceUrl;
  count = 0;

  materialItem = {
    name: "",
    description: "",
    price: 0,
    unit: "",
    size: "",
    photo: {
      url: "https://firebasestorage.googleapis.com/v0/b/constructora-56ce3.appspot.com/o/placeholder.jpeg?alt=media&token=53324723-0e82-41d8-b015-0d04cd815db0",
      name: "placeholder"
    }
  }

  constructor(private db: AngularFirestore,
    private router: Router,
    private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.loadName();
    this.loadData();
    
    this.dropdownList = this.materialsList;
    this.selectedItems = [];
    this.dropdownSettings = {
      singleSelection: false,
      enableCheckAll: false,
      text: "Select materials",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      classes: "myclass custom-class"
    };
    this.urlSafe = this.sanitizer.bypassSecurityTrustUrl("https://firebasestorage.googleapis.com/v0/b/constructora-56ce3.appspot.com/o/placeholder.jpeg?alt=media&token=53324723-0e82-41d8-b015-0d04cd815db0");
  }

  async loadData() {
    let scopesDocs = await (await this.db.collection("Scopes").ref.get()).docs;
    scopesDocs.forEach(item => {
      let scope: any = item.data();
      scope.id = item.id;
      this.scopeList.push(scope);
    });

    let materialDocs = await (await this.db.collection("Materials").ref.get()).docs;
    materialDocs.forEach(item => {
      let material: any = item.data();
      material.id = item.id;
      material.name = item.data().name != undefined ? item.data().name : "Error: " + material.id;
      if (item.data().photo == undefined)
        material.image = "https://firebasestorage.googleapis.com/v0/b/constructora-56ce3.appspot.com/o/placeholder.jpeg?alt=media&token=53324723-0e82-41d8-b015-0d04cd815db0";
      else
        material.image = item.data().photo.url;
      this.materialsList.push(material);
    });

    let supplierDocs = await (await this.db.collection("Suppliers").ref.get()).docs;
    supplierDocs.forEach(item => {
      let supplier: any = item.data();
      supplier.id = item.id;
      this.supplyersList.push(supplier);
    });
  }

  async loadName(){
    let projectsDocs = await (await this.db.collection("Correlative").doc("Projects"))
    .get().toPromise();

    this.count = projectsDocs.data().count + 1;
    
    this.item.name = "Cotización - " + moment().format("YYMM") + this.count;
  }

  addScopesToProject() {
    if (this.item.scopes.filter(x => x.id == this.selectedScope.id).length == 0) {
      this.selectedScope.budget = parseInt(this.scopeBudget.toString());
      this.selectedScope.materials = [];
      let newItem = this.selectedScope;
      this.item.scopes.push(newItem);
      this.item.budget += this.selectedScope.budget;
    }
    else
      alert("Scope is already add to the project")
  }

  addMaterials() {
    if (this.item.scopes[this.scopeIndex].materials.filter(x => x.id == this.selectedMaterial.id).length == 0) {
      this.selectedMaterial.quantity = this.quantity;
      this.selectedMaterial.total = this.quantity * this.price;
      this.item.scopes[this.scopeIndex].budget = this.item.scopes[this.scopeIndex].budget + (this.quantity * this.price);
      this.item.scopes[this.scopeIndex].materials.push(this.selectedMaterial);
      this.item.budget = this.item.budget + this.quantity * this.price;
    }
    else
      alert("Supplier is already add to the scope")
  }

  removeMaterial(id, scopeId) {
    if (!confirm("Are you sure of delete this item?")) return;
    let indexScopes = this.item.scopes.map(x => { return x.id }).indexOf(scopeId);
    let indexMat = this.item.scopes[indexScopes].materials.map(x => { return x.id }).indexOf(id);
    if (this.item.scopes[indexScopes].materials[indexMat] != undefined) {
      this.item.scopes[indexScopes].budget =
        this.item.scopes[indexScopes].budget -
        this.item.scopes[indexScopes].materials[indexMat].total;

      this.item.budget = this.item.budget - this.item.scopes[indexScopes].materials[indexMat].total;

      this.item.scopes[indexScopes].materials.splice(indexMat, 1);
    }
  }

  addSupplier() {
    if (this.item.supplyers.filter(x => x.id == this.selectedSupplier.id).length == 0)
      this.item.supplyers.push(this.selectedSupplier);
    else
      alert("Supplier is already add to the project")
  }


  async saveData() {
    if (!this.validate()) {
      alert("Complete all data");
      return;
    }
    if (!confirm("Are you sure of save this item?")) return;
    await this.db.collection("Projects").add(this.item);
    alert("Data saved successfully");
    this.db.collection("Correlative").doc("Projects").update({count : this.count + 1})
    this.router.navigate(['/app/proyects']);
  }

  keyword = 'name';


  onChangeSearch(val: string) {
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
  }

  onFocused(e) {
    // do something when input is focused
  }

  selectScope(item) {
    console.log(this.scopeBudget);
    let newItem = item;
    this.selectedScope = newItem;

  }

  selectMaterial(item) {
    let newItem = item;
    this.selectedMaterial = newItem;
    if (this.selectedMaterial.photo != undefined)
      this.urlSafe = this.sanitizer.bypassSecurityTrustUrl(this.selectedMaterial.photo.url);
    console.log(this.selectedMaterial);
    this.price = this.selectedMaterial.price;
  }

  selectSupplier(item) {
    let newItem = item;
    this.selectedSupplier = newItem;
  }

  showMaterials(materials) {
    let materiaStr = "";
    for (let i = 0; i < materials.length; i++) {
      materiaStr += "<p>" + materials[i].name + "(" + materials[i].quantity + ")" + "</p>";
    }
    return materiaStr;
  }

  newMaterial(id) {
    document.getElementById("newMaterialModalBtn").click();
    this.scopeIndex = this.scopeIndex = this.item.scopes.map(x => { return x.id }).indexOf(id);
  
  }

  openMaterials(id) {
    document.getElementById("openModalButton3").click();
    this.scopeIndex = this.scopeIndex = this.item.scopes.map(x => { return x.id }).indexOf(id);
  }

  removeSupplier(id) {
    if (!confirm("Are you sure of delete this item?")) return;
    let indexSupplier = this.item.supplyers.map(x => { return x.id }).indexOf(id);
    this.item.supplyers.splice(indexSupplier, 1);
  }

  removeScopes(id) {
    if (!confirm("Are you sure of delete this item?")) return;
    let indexScopes = this.item.scopes.map(x => { return x.id }).indexOf(id);
    this.item.budget -= this.item.scopes[indexScopes].budget;
    this.item.scopes.splice(indexScopes, 1);
  }

  //Action multiselect
  onItemSelect(item: any) {
    console.log(item);
    console.log(this.selectedItems);
  }
  OnItemDeSelect(item: any) {
    console.log(item);
    console.log(this.selectedItems);
  }
  onSelectAll(items: any) {
    console.log(items);
  }
  onDeSelectAll(items: any) {
    console.log(items);
  }

  validate() {
    if (this.item.name.length == 0
      || this.item.phone.length == 0
      || this.item.address.length == 0
      || this.item.scopes.length == 0
      || this.item.supplyers.length == 0) {
      return false;
    }

    return true;
  }

  async newMaterialToScope() {
    if (this.materialItem.name.length == 0 || this.materialItem.description.length == 0 || this.materialItem.price == 0
      || this.materialItem.size.length == 0 || this.materialItem.unit.length == 0) {
      alert("Complete all data");
      return;
    }
      this.materialItem.name = this.materialItem.name.trim().toLocaleUpperCase();
      let newMaterial = await (await this.db.collection("Materials").add(this.materialItem)).get();
      let savedMaterial = newMaterial.data();
      savedMaterial.id = newMaterial.id;
      savedMaterial.itemName = savedMaterial.name;
      this.item.scopes[this.scopeIndex].materials.push(savedMaterial);
      this.selectedMaterial = savedMaterial;
      this.selectedMaterial.quantity = this.quantity;
      this.selectedMaterial.total = this.quantity * savedMaterial.price;
      this.item.scopes[this.scopeIndex].budget = this.item.scopes[this.scopeIndex].budget + (this.quantity * savedMaterial.price);
      this.item.budget = this.item.budget + this.quantity * savedMaterial.price;

      document.getElementById("close-modal-newMaterial").click();
  }

}
