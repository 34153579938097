import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import readXlsxFile from 'read-excel-file'

@Component({
  selector: 'app-import-bulk-materials',
  templateUrl: './import-bulk-materials.component.html',
  styleUrls: ['./import-bulk-materials.component.css']
})
export class ImportBulkMaterialsComponent implements OnInit {

  public loading = false;
  blulkData = [];
  file: any;
  data: any;
  count = 0;
  fileReaded = false;

  constructor(public db: AngularFirestore,
    private router: Router,) { }

  ngOnInit() {
  }

  fileChanged(e) {
    this.file = e.target.files[0];
  }

  readFile() {
    if (!confirm("Are you sure of read this file?")) return;
    this.loading = true;
    this.blulkData = [];
    try {
      readXlsxFile(this.file).then(async (rows) => {
        this.data = rows;
        await this.readMaterials(rows)
        this.loading = false;
      }, errors => {
        console.log(errors);
        alert("Error reading file");
        this.loading = false;
        return;
      })
    } catch (error) {
      console.log(error);
      alert("Error reading file");
      this.loading = false;
      return;
    }
  }

  async readMaterials(rows) {
    try {
      for (let i = 1; i < rows.length; i++) {
        let materials = await (await this.db.collection("Materials").ref.where("name", "==", rows[i][0].trim().toUpperCase()).get()).docs;
        if (materials.length == 0) {
          let newItem: any = {};
          newItem.name = rows[i][0];
          newItem.name = newItem.name.trim().toLocaleUpperCase();
          newItem.description = rows[i][1];
          newItem.unit = rows[i][2];
          newItem.size = rows[i][3];
          newItem.price = parseFloat(rows[i][4]);
          newItem.photo = {
            url: "https://firebasestorage.googleapis.com/v0/b/constructora-56ce3.appspot.com/o/placeholder.jpeg?alt=media&token=53324723-0e82-41d8-b015-0d04cd815db0",
            name: "placeholder"
          }
          this.blulkData.push(newItem);
        }
      }
    } catch (error) {
      console.log(error);
      this.loading = false;
      return;
    }
    this.count = this.blulkData.length;
    this.fileReaded = true;
  }

  async saveFile() {
    if (!confirm("Are you sure of save this data?")) return;
    this.loading = true;
    for (let i = 0; i < this.blulkData.length; i++) {
      await this.db.collection("Materials").add(this.blulkData[i]);
    }
    this.loading = false;
    alert("Data saved");
    this.router.navigate(['/app/materials']);
  }

}
