import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { UiService } from 'src/app/services/ui.service';

@Component({
  selector: 'app-list-orders',
  templateUrl: './list-orders.component.html',
  styleUrls: ['./list-orders.component.css']
})
export class ListOrdersComponent implements OnInit {

  orderList: any = [];
  dtOptions: any = {};
  dtTrigger: Subject<DataTables.DataTables> = new Subject();
  @ViewChild(DataTableDirective, { static: false }) datatableElement: DataTableDirective;

  constructor(private db: AngularFirestore,
    public uiService: UiService) { }

  ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      // Declare the use of the extension in the dom parameter
      dom: 'Bfrtip',
      // Configure the buttons
      buttons: [
        'print',
        'excel',
      ],
      pageLength: 8, //language: GlobalConstants.espanol
    };
    this.loadData();
  }

  async loadData() {
    this.orderList = await (await this.db.collection("Orders").ref.get()).docs;
    this.dtTrigger.next();
  }

  delete(docKey) {
    if (!confirm("Are you sure of delete this item?")) return;
    var index = index = this.orderList.map(x => { return x.id }).indexOf(docKey);
    this.deleteItem(docKey)
      .then(
        res => {
          this.orderList.splice(index, 1);
          this.rerender();
        },
        err => {
          console.log(err);
        }
      )
  }

  deleteItem(docKey) {
    return this.db.collection('Orders').doc(docKey).delete();
  }

  rerender(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }
}
