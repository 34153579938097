import { Injectable } from '@angular/core';
import { GlobalConstants } from '../common/shared/GlobalConstans';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor() { }

  isAuthenticated() {
    if (GlobalConstants.user != undefined) return true;
    return false;
  }

  getRole() {
    return GlobalConstants.user.type;
  }

}
