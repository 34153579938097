import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import jsPDF from 'jspdf';
import * as moment from 'moment';
import { CustomCurrencyPipe } from 'src/app/common/helpers/custom-currency-pipe';
import { UiService } from 'src/app/services/ui.service';

@Component({
  selector: 'app-update-proyect',
  templateUrl: './update-proyect.component.html',
  styleUrls: ['./update-proyect.component.css']
})
export class UpdateProyectComponent implements OnInit {

  item = {
    price: 0,
    name: "",
    phone: "",
    budget: 0,
    address: "",
    scopes: [],
    supplyers: [],
    taxPercent: 0,
    initDate: moment().format("YYYY-MM-DD")
  }

  scopeBudget = 0;
  scopeList: any = [];
  materialsList: any = [];
  supplyersList: any = [];
  selectedScope: any;
  selectedMaterial: any;
  selectedSupplier: any;

  currentScope: any;
  scopeIndex = 0;

  docKey = ""

  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  price = 0;
  quantity = 0;
  urlSafe: SafeResourceUrl;
  keyword = 'name';

  materialItem = {
    name: "",
    description: "",
    price: 0,
    unit: "",
    size: "",
    photo: {
      url: "https://firebasestorage.googleapis.com/v0/b/constructora-56ce3.appspot.com/o/placeholder.jpeg?alt=media&token=53324723-0e82-41d8-b015-0d04cd815db0",
      name: "placeholder"
    }
  }

  constructor(private db: AngularFirestore,
    private router: Router,
    private actRoute: ActivatedRoute,
    private currency: CustomCurrencyPipe,
    private sanitizer: DomSanitizer,
    public uiService: UiService) { }

  ngOnInit() {
    this.loadData();

    // this.dropdownList = this.materialsList;
    // this.selectedItems = [];
    // this.dropdownSettings = {
    //   singleSelection: false,
    //   enableCheckAll: false,
    //   text: "Select materials",
    //   selectAllText: 'Select All',
    //   unSelectAllText: 'UnSelect All',
    //   enableSearchFilter: true,
    //   classes: "myclass custom-class"
    // };
    this.urlSafe = this.sanitizer.bypassSecurityTrustUrl("https://firebasestorage.googleapis.com/v0/b/constructora-56ce3.appspot.com/o/placeholder.jpeg?alt=media&token=53324723-0e82-41d8-b015-0d04cd815db0");

  }

  load(docKey) {
    return new Promise<any>((resolve, reject) => {
      this.db.collection('Projects').doc(docKey).valueChanges()
        .subscribe(snapshots => {
          resolve(snapshots)
        })
    })
  }

  updateData() {
    if (!this.validate()) {
      alert("Complete all data");
      return;
    }
    if (!confirm("Are you sure of save this item?")) return;
    this.db.collection('Projects').doc(this.docKey).set(this.item);
    alert("Data saved successfully");
    this.router.navigate(['/app/proyects']);
  }

  async loadData() {
    let scopesDocs = await (await this.db.collection("Scopes").ref.get()).docs;
    scopesDocs.forEach(item => {
      let scope: any = item.data();
      scope.id = item.id;
      this.scopeList.push(scope);
    });

    let materialDocs = await (await this.db.collection("Materials").ref.get()).docs;
    materialDocs.forEach(item => {
      let material: any = item.data();
      material.id = item.id;
      material.name = item.data().name != undefined ? item.data().name : "Error: " + material.id;
      if (item.data().photo == undefined)
        material.image = "https://firebasestorage.googleapis.com/v0/b/constructora-56ce3.appspot.com/o/placeholder.jpeg?alt=media&token=53324723-0e82-41d8-b015-0d04cd815db0";
      else
        material.image = item.data().photo.url;
      this.materialsList.push(material);
    });

    let supplierDocs = await (await this.db.collection("Suppliers").ref.get()).docs;
    supplierDocs.forEach(item => {
      let supplier: any = item.data();
      supplier.id = item.id;
      this.supplyersList.push(supplier);
    });

    this.docKey = this.actRoute.snapshot.paramMap.get('id');
    this.load(this.docKey).then(result => {
      this.item = result;
    });

    console.log(this.materialsList);
  }

  addScopesToProject() {
    if (this.item.scopes.filter(x => x.id == this.selectedScope.id).length == 0) {
      this.selectedScope.budget = parseInt(this.scopeBudget.toString());
      this.selectedScope.materials = [];
      let newItem = this.selectedScope;
      this.item.scopes.push(newItem);
      this.item.budget += this.selectedScope.budget;
    }
    else
      alert("Scope is already add to the project")
  }

  addMaterials() {
    if (this.item.scopes[this.scopeIndex].materials.filter(x => x.id == this.selectedMaterial.id).length == 0) {
      this.selectedMaterial.quantity = this.quantity;
      this.selectedMaterial.total = this.quantity * this.price;
      this.item.scopes[this.scopeIndex].budget = this.item.scopes[this.scopeIndex].budget + (this.quantity * this.price);
      this.item.scopes[this.scopeIndex].materials.push(this.selectedMaterial);
      this.item.budget = this.item.budget + this.quantity * this.price;
    }
    else
      alert("Supplier is already add to the scope")
  }

  removeMaterial(id, scopeId) {
    if (!confirm("Are you sure of delete this item?")) return;
    let indexScopes = this.item.scopes.map(x => { return x.id }).indexOf(scopeId);
    let indexMat = this.item.scopes[indexScopes].materials.map(x => { return x.id }).indexOf(id);
    if (this.item.scopes[indexScopes].materials[indexMat] != undefined) {
      this.item.scopes[indexScopes].budget =
        this.item.scopes[indexScopes].budget -
        this.item.scopes[indexScopes].materials[indexMat].total;

      this.item.budget = this.item.budget - this.item.scopes[indexScopes].materials[indexMat].total;

      this.item.scopes[indexScopes].materials.splice(indexMat, 1);
    }
  }

  addSupplier() {
    this.item.supplyers.push(this.selectedSupplier);
  }





  onChangeSearch(val: string) {
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
  }

  onFocused(e) {
    // do something when input is focused
  }

  selectScope(item) {
    console.log(this.scopeBudget);
    let newItem = item;
    this.selectedScope = newItem;

  }

  selectMaterial(item) {
    let newItem = item;
    this.selectedMaterial = newItem;
    if (this.selectedMaterial.photo != undefined)
      this.urlSafe = this.sanitizer.bypassSecurityTrustUrl(this.selectedMaterial.photo.url);
    console.log(this.selectedMaterial);
    this.price = this.selectedMaterial.price;
  }

  selectSupplier(item) {
    let newItem = item;
    this.selectedSupplier = newItem;
  }

  showMaterials(materials) {
    let materiaStr = "";
    for (let i = 0; i < materials.length; i++) {
      materiaStr += materials[i].name + "/";
    }
    return materiaStr;
  }

  openMaterials(id) {
    document.getElementById("openModalButton3").click();
    this.scopeIndex = this.scopeIndex = this.item.scopes.map(x => { return x.id }).indexOf(id);
  }

  removeSupplier(id) {
    let indexSupplier = this.item.supplyers.map(x => { return x.id }).indexOf(id);
    this.item.supplyers.splice(indexSupplier, 1);
  }

  removeScopes(id) {
    let indexScopes = this.item.scopes.map(x => { return x.id }).indexOf(id);
    this.item.budget -= this.item.scopes[indexScopes].budget;
    this.item.scopes.splice(indexScopes, 1);
  }

  //Action multiselect
  onItemSelect(item: any) {
    console.log(item);
    console.log(this.selectedItems);
  }
  OnItemDeSelect(item: any) {
    console.log(item);
    console.log(this.selectedItems);
  }
  onSelectAll(items: any) {
    console.log(items);
  }
  onDeSelectAll(items: any) {
    console.log(items);
  }
  onChangeDate() {

  }

  validate() {
    if (this.item.name.length == 0
      || this.item.phone.length == 0
      || (this.item.address && this.item.address.length == 0)
      || this.item.scopes.length == 0
      || this.item.supplyers.length == 0) {
      return false;
    }

    return true;
  }

  async exportToPDF() {
    if (!this.validate()) {
      alert("Complete all data");
      return;
    }
    const doc = new jsPDF();

    doc.addImage("assets/img/stm-logo-pdf.png", "PNG", 10, 10, 25, 20);
    doc.setFillColor(6, 60, 124);
    doc.setFontSize(14);
    doc.text('Soluciones técnicas y mantenimiento', 10, 40);
    doc.setFont("helvetica", "bold");
    doc.text('Resumén del proyecto', 140, 20);
    doc.setFontSize(11);
    doc.setTextColor(0, 0, 0);
    doc.text(`Subtotal: ${this.currency.transform(this.item.budget)}`, 140, 30);
    doc.text(`Impuestos: ${this.currency.transform(this.item.budget * (this.item.taxPercent / 100))}`, 140, 36);
    doc.text(`Total: ${this.currency.transform(this.item.budget + (this.item.budget * (this.item.taxPercent / 100)))}`, 140, 42);
    doc.setFont("helvetica", "normal");
    doc.text('Colonia Montefresco', 10, 50);
    doc.text('23 calle, 12 avenida SE', 10, 55);
    doc.text('Teléfono: 99344370 / 25542174', 10, 60);

    doc.setDrawColor(0);
    doc.setFillColor(6, 60, 124);
    doc.rect(10, 65, 85, 10, "F");
    doc.rect(120, 65, 85, 10, "F");
    doc.setTextColor(255, 255, 255);
    doc.setFont("helvetica", "bold");
    doc.text('Cliente', 11, 72);
   // doc.text('Proyecto', 121, 72);
    doc.setFont("helvetica", "normal");
    //doc.text(this.item.supplyers[0].name, 30, 72);
    doc.setFontSize(9);
    doc.text(this.item.name, 121, 72);
    doc.setTextColor(0, 0, 0);
    doc.text('Dirección del proyecto:', 121, 80);
    doc.setFontSize(9);
    doc.text(this.item.address, 121, 85);//TODO
    doc.setFontSize(8);
    let yLineCell = 73;
    let yLineText = 80;

    for (let i = 0; i < this.item.supplyers.length; i++) {
      doc.setDrawColor(0);
      doc.setFillColor(255, 255, 255);
      doc.rect(10, yLineCell, 85, 10, "FD");
      doc.text(this.item.supplyers[i].name, 11, yLineText);
      doc.setTextColor(0, 0, 0);
      yLineCell = yLineCell + 10;
      yLineText = yLineText + 10;
    }

    yLineCell = yLineCell + 20;
    yLineText = yLineText + 20;

    for (let i = 0; i < this.item.scopes.length; i++) {

      doc.setDrawColor(0);
      doc.setFillColor(6, 60, 124);
      doc.rect(10, yLineCell, 80, 10, "FD");
      doc.setTextColor(255, 255, 255);
      doc.text(`Categoría: ${this.item.scopes[i].name} Total ${this.currency.transform(this.item.scopes[i].budget)}`, 11, yLineText);

      yLineCell = yLineCell + 10;
      yLineText = yLineText + 10;

      doc.setDrawColor(0);
      doc.setFillColor(6, 60, 124);
      doc.rect(10, yLineCell, 130, 10, "FD");
      doc.rect(140, yLineCell, 20, 10, "FD");
      doc.rect(160, yLineCell, 20, 10, "FD");
      doc.rect(180, yLineCell, 20, 10, "FD");

      doc.setTextColor(255, 255, 255);
      doc.text("DESCRIPCIÓN", 11, yLineText);
      doc.text("CANT.", 141, yLineText);
      doc.text("PRECIO", 161, yLineText);
      doc.text("TOTAL", 181, yLineText);

      yLineCell = yLineCell + 10;
      yLineText = yLineText + 10;

      for (let j = 0; j < this.item.scopes[i].materials.length; j++) {
        let description = this.item.scopes[i].materials[j].name;
        let lines = doc.splitTextToSize(description, 110);

        doc.setDrawColor(0);
        doc.setFillColor(255, 255, 255);
        doc.rect(10, yLineCell, 130, 10+(5*lines.length), "FD");
        doc.rect(140, yLineCell, 20, 10+(5*lines.length), "FD");
        doc.rect(160, yLineCell, 20, 10+(5*lines.length), "FD");
        doc.setDrawColor(0);
        doc.setFillColor(6, 60, 124);
        doc.rect(180, yLineCell, 20, 10+(5*lines.length), "FD");
        doc.setTextColor(0, 0, 0);
        
        //doc.text(this.item.scopes[i].materials[j].name, 11, yLineText);
        doc.text(this.item.scopes[i].materials[j].quantity.toString(), 141, yLineText);
        doc.text(this.currency.transform(this.item.scopes[i].materials[j].price.toString()), 161, yLineText);
        doc.setTextColor(255, 255, 255);
        doc.text(this.currency.transform(this.item.scopes[i].materials[j].total.toString()), 181, yLineText);
        doc.setTextColor(0, 0, 0);
        
        doc.setFillColor(255, 255, 255);
        
        for (let k = 0; k < lines.length; k++) {
          doc.text(lines[k], 11, yLineText);
          yLineText += 5;
          yLineCell +=5;
        }
        
        yLineCell = yLineCell + 10;
        yLineText = yLineText + 10;

        if (yLineText >= 257) {
          doc.addPage();
          yLineCell = 10;
          yLineText = 17;
        }
      }

    }

    doc.setFontSize(9);
    doc.text("OBSERVACION: propuesta valida por 30 días, el trabajo se realizará de acuerdo a conveniencia", 10, yLineText+10);
    doc.text("del cliente previa planificación con el contratista.", 10, yLineText+15);
    doc.text("Gracias por permitirlos tener la posibilidad de servirles cualquier observación estamos a la orden.", 10, yLineText+30);
    doc.text("(recuerda haz grande a los pequeños y serás aún más grande)", 10, yLineText+35);

    doc.save(`STM-${this.item.name}.pdf`);
  }

  createHeaders(keys) {
    var result = [];
    for (var i = 0; i < keys.length; i += 1) {
      result.push({
        id: keys[i],
        name: keys[i],
        prompt: keys[i],
        width: 150,
        align: "left"
      });
    }
    return result;
  }

  cancel() {
    this.router.navigate(['/app/proyects']);
  }

  newMaterial(id) {
    document.getElementById("newMaterialModalBtn").click();
    this.scopeIndex = this.scopeIndex = this.item.scopes.map(x => { return x.id }).indexOf(id);
  }

  async newMaterialToScope() {
    if (this.materialItem.name.length == 0 || this.materialItem.description.length == 0 || this.materialItem.price == 0
      || this.materialItem.size.length == 0 || this.materialItem.unit.length == 0) {
      alert("Complete all data");
      return;
    }
      this.materialItem.name = this.materialItem.name.trim().toLocaleUpperCase();
      let newMaterial = await (await this.db.collection("Materials").add(this.materialItem)).get();
      let savedMaterial = newMaterial.data();
      savedMaterial.id = newMaterial.id;
      savedMaterial.itemName = savedMaterial.name;
      this.item.scopes[this.scopeIndex].materials.push(savedMaterial);
      this.selectedMaterial = savedMaterial;
      this.selectedMaterial.quantity = this.quantity;
      this.selectedMaterial.total = this.quantity * savedMaterial.price;
      this.item.scopes[this.scopeIndex].budget = this.item.scopes[this.scopeIndex].budget + (this.quantity * savedMaterial.price);
      this.item.budget = this.item.budget + this.quantity * savedMaterial.price;

      document.getElementById("close-modal-newMaterial").click();
  }
}
