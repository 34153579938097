import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { UploadImagesService } from 'src/app/services/storage/upload-images.service';

@Component({
  selector: 'app-update-materials',
  templateUrl: './update-materials.component.html',
  styleUrls: ['./update-materials.component.css']
})
export class UpdateMaterialsComponent implements OnInit {

  item = {
    name: "",
    description: "",
    price: 0.00,
    unit: "",
    size: "",
    photo: {
      name: "placeholder",
      url: "https://firebasestorage.googleapis.com/v0/b/constructora-56ce3.appspot.com/o/placeholder.jpeg?alt=media&token=53324723-0e82-41d8-b015-0d04cd815db0"
    }
  }

  photo;
  docKey = "";
  urlSafe: SafeResourceUrl;
  file: File;
  lastPhoto;

  constructor(private router: Router,
    public db: AngularFirestore,
    private actRoute: ActivatedRoute,
    private uploadImagesService: UploadImagesService,
    private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.docKey = this.actRoute.snapshot.paramMap.get('id');
    this.load(this.docKey).then(result => {
      this.item = result;
      if (this.item.photo == undefined) {
        this.item.photo = {
          name: "placeholder",
          url: "https://firebasestorage.googleapis.com/v0/b/constructora-56ce3.appspot.com/o/placeholder.jpeg?alt=media&token=53324723-0e82-41d8-b015-0d04cd815db0"
        }
      }
      this.urlSafe = this.sanitizer.bypassSecurityTrustUrl(this.item.photo.url);

    });
  }

  load(docKey) {
    return new Promise<any>((resolve, reject) => {
      this.db.collection('Materials').doc(docKey).valueChanges()
        .subscribe(snapshots => {
          resolve(snapshots)
        })
    })
  }

  async updateData() {
    if (!this.validate()) {
      alert("Complete all data");
      return;
    }

    let materials = await (await this.db.collection("Materials").ref.where("name", "==", this.item.name.trim().toUpperCase()).get()).docs;
    if (materials.length > 0) {
      if (materials[0].id != this.docKey) {
        alert("Material already exist in the Data Base");
        return;
      }
    }

    if (!confirm("Are you sure of save this item?")) return;

    if (this.file != undefined) {
      if (this.lastPhoto != "placeholder") {
        let deletedItem = await this.uploadImagesService.deleteFile("materials", this.lastPhoto);
        deletedItem.subscribe(async item => {
          let result: any = await this.uploadImagesService.uploadImage("materials", this.item.photo.name, this.file);
          result.subscribe(async url => {
            this.item.photo.url = url;
            this.item.name = this.item.name.trim().toLocaleUpperCase();
            this.db.collection('Materials').doc(this.docKey).set(this.item);
            alert("Data saved successfully");
            this.router.navigate(['/app/materials']);
          })
        });
      }
      else {
        let result: any = await this.uploadImagesService.uploadImage("materials", this.item.photo.name, this.file);
        result.subscribe(async url => {
          this.item.photo.url = url;
          this.item.name = this.item.name.trim().toLocaleUpperCase();
          this.db.collection('Materials').doc(this.docKey).set(this.item);
          alert("Data saved successfully");
          this.router.navigate(['/app/materials']);
        })
      }

    }
    else {
      this.item.name = this.item.name.trim().toLocaleUpperCase();
      this.db.collection('Materials').doc(this.docKey).set(this.item);
      alert("Data saved successfully");
      this.router.navigate(['/app/materials']);
    }

  }

  validate() {
    if (this.item.name.length == 0
      || (this.item.description != undefined && this.item.description.length == 0)
      || (this.item.unit != undefined && this.item.unit.length == 0)
      || (this.item.size != undefined && this.item.size.length == 0))
      return false;

    return true;
  }
  async uploadTeacherImage(event) {
    if ((event.target.files[0].size / 1024) > 1000) {
      alert("File is to large, max file size is 1MB");
      this.photo = "";
      return;
    }
    this.item.photo.url = URL.createObjectURL(event.target.files[0]);
    this.urlSafe = this.sanitizer.bypassSecurityTrustUrl(this.item.photo.url);
    this.lastPhoto = this.item.photo.name;
    this.item.photo.name = event.target.files[0].name + new Date().toDateString() + new Date().toLocaleTimeString()
    this.file = event.target.files[0];
  }

}
