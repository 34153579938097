import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainComponent } from './common/main/main.component';
import { LoginComponent } from './features/authentication/login/login.component';
import { FormsModule } from '@angular/forms';
import { SideMenuComponent } from './common/side-menu/side-menu.component';
import { TopBarComponent } from './common/top-bar/top-bar.component';
import { HomeComponent } from './common/home/home.component';
import { ListProyectsComponent } from './features/proyects/list-proyects/list-proyects.component';
import { ListUsersComponent } from './features/authentication/list-users/list-users.component';
import { DataTablesModule } from 'angular-datatables';
import { ListMaterialsComponent } from './features/materials/list-materials/list-materials.component';
import { ListScopesComponent } from './features/scopes/list-scopes/list-scopes.component';
import { CreateProyectComponent } from './features/proyects/create-proyect/create-proyect.component';
import { CreateMaterialComponent } from './features/materials/create-material/create-material.component';
import { CreateUserComponent } from './features/authentication/create-user/create-user.component';
import { CreateScopesComponent } from './features/scopes/create-scopes/create-scopes.component';
import { ListOrdersComponent } from './features/orders/list-orders/list-orders.component';
import { CreateOrderComponent } from './features/orders/create-order/create-order.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireFunctionsModule } from '@angular/fire/functions';
import { environment } from 'src/environments/environment';
import { ListSuppliersComponent } from './features/suppliers/list-suppliers/list-suppliers.component';
import { CreateSupplierComponent } from './features/suppliers/create-supplier/create-supplier.component';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { UpdateSupplierComponent } from './features/suppliers/update-supplier/update-supplier.component';
import { UpdateMaterialsComponent } from './features/materials/update-materials/update-materials.component';
import { UpdateScopesComponent } from './features/scopes/update-scopes/update-scopes.component';
import { UpdateUsersComponent } from './features/authentication/update-users/update-users.component';
import { UpdateProyectComponent } from './features/proyects/update-proyect/update-proyect.component';
import { UpdateOrderComponent } from './features/orders/update-order/update-order.component';
import { ProjectOrdersComponent } from './features/proyects/reports/project-orders/project-orders.component';
import { CurrencyPipe } from '@angular/common';
import { UploadImagesService } from './services/storage/upload-images.service';
import { ImportBulkMaterialsComponent } from './features/materials/import-bulk-materials/import-bulk-materials.component';
import { NgxLoadingModule } from 'ngx-loading';
import { ImportBlulkProyectsComponent } from './features/proyects/import-blulk-proyects/import-blulk-proyects.component';
import { CustomCurrencyPipe } from './common/helpers/custom-currency-pipe';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    MainComponent,
    SideMenuComponent,
    TopBarComponent,
    HomeComponent,
    ListProyectsComponent,
    ListUsersComponent,
    ListMaterialsComponent,
    ListScopesComponent,
    CreateProyectComponent,
    CreateMaterialComponent,
    CreateUserComponent,
    CreateScopesComponent,
    ListOrdersComponent,
    CreateOrderComponent,
    ListSuppliersComponent,
    CreateSupplierComponent,
    UpdateSupplierComponent,
    UpdateMaterialsComponent,
    UpdateScopesComponent,
    UpdateUsersComponent,
    UpdateProyectComponent,
    UpdateOrderComponent,
    ProjectOrdersComponent,
    ImportBulkMaterialsComponent,
    ImportBlulkProyectsComponent,
    CustomCurrencyPipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularMultiSelectModule,
    FormsModule,
    DataTablesModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireFunctionsModule,
    AutocompleteLibModule,
    NgxLoadingModule.forRoot({})

  ],
  providers: [CurrencyPipe, UploadImagesService, CustomCurrencyPipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
