import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';

@Component({
  selector: 'app-create-scopes',
  templateUrl: './create-scopes.component.html',
  styleUrls: ['./create-scopes.component.css']
})
export class CreateScopesComponent implements OnInit {

  item = {
    name: "",
    description: "",
  }

  constructor(private db: AngularFirestore, private router: Router) { }

  ngOnInit() {
  }

  async saveData() {
    if (!confirm("Are you sure of save this item?")) return;
    await this.db.collection("Scopes").add(this.item);
    alert("Data saved successfully");
    this.router.navigate(['/app/scopes']);
  }

}
