import { ImportBulkMaterialsComponent } from './features/materials/import-bulk-materials/import-bulk-materials.component';
import { ProjectOrdersComponent } from './features/proyects/reports/project-orders/project-orders.component';
import { UpdateOrderComponent } from './features/orders/update-order/update-order.component';
import { UpdateProyectComponent } from './features/proyects/update-proyect/update-proyect.component';
import { UpdateUsersComponent } from './features/authentication/update-users/update-users.component';
import { UpdateScopesComponent } from './features/scopes/update-scopes/update-scopes.component';
import { UpdateSupplierComponent } from './features/suppliers/update-supplier/update-supplier.component';
import { CreateSupplierComponent } from './features/suppliers/create-supplier/create-supplier.component';
import { ListSuppliersComponent } from './features/suppliers/list-suppliers/list-suppliers.component';
import { CreateOrderComponent } from './features/orders/create-order/create-order.component';
import { ListOrdersComponent } from './features/orders/list-orders/list-orders.component';
import { CreateUserComponent } from './features/authentication/create-user/create-user.component';
import { CreateScopesComponent } from './features/scopes/create-scopes/create-scopes.component';
import { CreateMaterialComponent } from './features/materials/create-material/create-material.component';
import { CreateProyectComponent } from './features/proyects/create-proyect/create-proyect.component';
import { ListScopesComponent } from './features/scopes/list-scopes/list-scopes.component';
import { ListMaterialsComponent } from './features/materials/list-materials/list-materials.component';
import { ListProyectsComponent } from './features/proyects/list-proyects/list-proyects.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './features/authentication/login/login.component';
import { MainComponent } from './common/main/main.component';
import { HomeComponent } from './common/home/home.component';
import { ListUsersComponent } from './features/authentication/list-users/list-users.component';
import { AuthGuard } from './auth/auth.guard';
import { UpdateMaterialsComponent } from './features/materials/update-materials/update-materials.component';
import { ImportBlulkProyectsComponent } from './features/proyects/import-blulk-proyects/import-blulk-proyects.component';


const routes: Routes = [
  { path: "", pathMatch: "full", redirectTo: "/login" },
  { path: "login", component: LoginComponent },
  {
    path: "app",
    component: MainComponent,

    children: [
      { path: "home", component: HomeComponent, canActivate: [AuthGuard], data: { role: ['projects', 'admin', 'dbAcces'] } },
      { path: "proyects", component: ListProyectsComponent, canActivate: [AuthGuard], data: { role: ['admin', 'projects', 'dbAcces'] } },
      { path: "create-proyect", component: CreateProyectComponent, canActivate: [AuthGuard], data: { role: ['admin'] } },
      { path: "update-proyect/:id", component: UpdateProyectComponent, canActivate: [AuthGuard], data: { role: ['admin', 'projects', 'dbAcces'] } },
      { path: "project-orders/:id", component: ProjectOrdersComponent, canActivate: [AuthGuard], data: { role: ['admin', 'projects'] } },
      {
        path: "import-projects", component: ImportBlulkProyectsComponent, canActivate: [AuthGuard], data: {
          role: ['admin', 'projects']
        }
      },

      { path: "users", component: ListUsersComponent, canActivate: [AuthGuard], data: { role: ['admin'] } },
      { path: "create-user", component: CreateUserComponent, canActivate: [AuthGuard], data: { role: ['admin'] } },
      { path: "update-user/:id", component: UpdateUsersComponent, canActivate: [AuthGuard], data: { role: ['admin'] } },

      { path: "materials", component: ListMaterialsComponent, canActivate: [AuthGuard], data: { role: ['admin'] } },
      { path: "create-material", component: CreateMaterialComponent, canActivate: [AuthGuard], data: { role: ['admin'] } },
      { path: "update-material/:id", component: UpdateMaterialsComponent, canActivate: [AuthGuard], data: { role: ['admin'] } },
      { path: "import-materials", component: ImportBulkMaterialsComponent, canActivate: [AuthGuard], data: { role: ['admin'] } },

      { path: "scopes", component: ListScopesComponent, canActivate: [AuthGuard], data: { role: ['admin'] } },
      { path: "create-scope", component: CreateScopesComponent, canActivate: [AuthGuard], data: { role: ['admin'] } },
      { path: "update-scope/:id", component: UpdateScopesComponent, canActivate: [AuthGuard], data: { role: ['admin'] } },

      { path: "vendors", component: ListSuppliersComponent, canActivate: [AuthGuard], data: { role: ['admin'] } },
      { path: "create-vendor", component: CreateSupplierComponent, canActivate: [AuthGuard], data: { role: ['admin'] } },
      { path: "update-vendor/:id", component: UpdateSupplierComponent, canActivate: [AuthGuard], data: { role: ['admin'] } },

      { path: "orders", component: ListOrdersComponent, canActivate: [AuthGuard], data: { role: ['admin', 'dbAcces', 'projects'] } },
      { path: "create-order", component: CreateOrderComponent, canActivate: [AuthGuard], data: { role: ['admin', 'dbAcces', 'projects'] } },
      { path: "update-order/:id", component: UpdateOrderComponent, canActivate: [AuthGuard], data: { role: ['admin', 'dbAcces', 'projects'] } },

    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
