import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-update-users',
  templateUrl: './update-users.component.html',
  styleUrls: ['./update-users.component.css']
})
export class UpdateUsersComponent implements OnInit {

  item = {
    user: "",
    email: "",
    phone: "",
    password: "",
    type: []
  }

  admin = false;
  dbAcces = false;
  projects = false;

  fieldTextType: boolean;

  docKey = "";

  constructor(private router: Router,
    public db: AngularFirestore, private actRoute: ActivatedRoute,) { }

  ngOnInit() {
    this.docKey = this.actRoute.snapshot.paramMap.get('id');
    this.load(this.docKey).then(result => {
      this.item = result;
      if (this.item.type.indexOf("admin") != -1) this.admin = true;
      if (this.item.type.indexOf("dbAcces") != -1) this.dbAcces = true;
      if (this.item.type.indexOf("projects") != -1) this.projects = true;
    });
  }

  load(docKey) {
    return new Promise<any>((resolve, reject) => {
      this.db.collection('User').doc(docKey).valueChanges()
        .subscribe(snapshots => {
          resolve(snapshots)
        })
    })
  }

  updateData() {
    if (this.admin && this.item.type.indexOf("admin") == -1) this.item.type.push("admin")
    if (this.dbAcces && this.item.type.indexOf("dbAcces") == -1) this.item.type.push("dbAcces")
    if (this.projects && this.item.type.indexOf("projects") == -1) this.item.type.push("projects")

    if (!this.admin && this.item.type.indexOf("admin") != -1) this.item.type.splice(this.item.type.indexOf("admin"), 1);
    if (!this.dbAcces && this.item.type.indexOf("dbAcces") != -1) this.item.type.splice(this.item.type.indexOf("dbAcces"), 1);
    if (!this.projects && this.item.type.indexOf("projects") != -1) this.item.type.splice(this.item.type.indexOf("projects"), 1);

    if (!this.validate()) {
      alert("Complete all data");
      return;
    }

    if (!confirm("Are you sure of save this item?")) return;

    this.db.collection('User').doc(this.docKey).set(this.item);
    alert("Data saved successfully");
    this.router.navigate(['/app/users']);
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  validate() {
    console.log(this.item)
    if (this.item.user.length == 0
      || this.item.email.length == 0
      || this.item.phone.length == 0
      || this.item.password.length == 0
      || this.item.type.length == 0) {
      return false;
    }

    return true;
  }

}
