import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { resolve } from 'url';

@Injectable({
  providedIn: 'root'
})
export class UploadImagesService {

  constructor(private storage: AngularFireStorage) { }

  public async uploadImage(folder, name, file) {
    let newFileName = folder + "/" + name;
    const uploadedFile = await this.uploadImageToFirestore(newFileName, file);
    const ref = await this.referenciaStorage(newFileName);
    console.log(ref);
    return ref.getDownloadURL();
  }

  public async deleteFile(folder, name) {
    let newFileName = folder + "/" + name;
    const ref = await this.referenciaStorage(newFileName);
    return ref.delete();
  }

  // upload files toserver
  private async uploadImageToFirestore(nombreImagen: string, file: any) {
    return await this.storage.upload(nombreImagen, file);
  }

  private async referenciaStorage(nombreImagen: string) {
    return await this.storage.ref(nombreImagen);
  }


}
