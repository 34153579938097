import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { jsPDF } from "jspdf";
import { CurrencyPipe } from '@angular/common';
import { UploadImagesService } from 'src/app/services/storage/upload-images.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { GlobalConstants } from 'src/app/common/shared/GlobalConstans';
import { AngularFireFunctions } from '@angular/fire/functions';

@Component({
  selector: 'app-create-order',
  templateUrl: './create-order.component.html',
  styleUrls: ['./create-order.component.css']
})
export class CreateOrderComponent implements OnInit {

  projectList: any = [];
  materialList: any = [];
  selectedProject: any = undefined;
  scope;
  supplier;
  material;
  userName = GlobalConstants.user.user;
  orderPdf;
  item = {
    projectName: "",
    scope: "",
    scopeKey: "",
    supplier: "",
    supplierKey: "",
    materials: [],
    date: moment().format("YYYY/MM/DD"),
    total: 0,
    projectKey: "",
    comments: "",
    taxPercent: 0,
    purchaseOrderNumber: 0
  }

  materialItem = {
    name: "",
    description: "",
    price: 0,
    unit: "",
    size: "",
    photo: {
      url: "https://firebasestorage.googleapis.com/v0/b/constructora-56ce3.appspot.com/o/placeholder.jpeg?alt=media&token=53324723-0e82-41d8-b015-0d04cd815db0",
      name: "placeholder"
    }
  }

  remaining = 0;
  total = 0;
  expended = 0;
  quantity = 0;
  price = 0;

  photo;
  urlSafe: SafeResourceUrl;
  file: File;

  constructor(private db: AngularFirestore,
    private router: Router,
    private currency: CurrencyPipe,
    private uploadImagesService: UploadImagesService,
    private sanitizer: DomSanitizer,
    private functions: AngularFireFunctions) { }

  ngOnInit() {
    this.loadData();
  }

  async loadData() {
    let projectsDocs = await (await this.db.collection("Projects").ref.get()).docs;
    projectsDocs.forEach(item => {
      let project: any = item.data();
      project.id = item.id;
      this.projectList.push(project);
    });
    this.updateMaterials();
    this.urlSafe = this.sanitizer.bypassSecurityTrustUrl(this.materialItem.photo.url);
  }

  async updateMaterials() {
    let materialDocs = await (await this.db.collection("Materials").ref.get()).docs
    materialDocs.forEach(item => {
      let material: any = item.data();
      material.id = item.id;
      this.materialList.push(material);
    });
  }

  selectProject(item) {
    this.selectedProject = item;
    this.item.projectKey = this.selectedProject.id;
    this.item.projectName = this.selectedProject.name;
    this.item.taxPercent = this.selectedProject.taxPercent;
  }

  selectSupplyer(item) {
    this.supplier = item;
    this.item.supplier = this.supplier.name;
    this.item.supplierKey = this.supplier.id;
  }

  selectScope(item) {
    this.scope = item;
    this.item.scope = this.scope.name;
    this.item.scopeKey = this.scope.id;

    this.total = this.scope.budget;
    this.calculateTotal();

  }

  async calculateTotal() {
    this.expended = 0;
    let ordersDocs = await (await this.db.collection("Orders").ref.where("projectKey", "==", this.selectedProject.id).get()).docs;
    if (ordersDocs.length > 0)
      for (let i = 0; i < ordersDocs.length; i++) {
        if (ordersDocs[i].data().scopeKey == this.item.scopeKey)
          this.expended += ordersDocs[i].data().total;
      }
    this.remaining = this.total - this.expended;
  }

  selectMaterial(item) {
    this.material = item;
    this.price = this.material.price;
  }

  addMaterial(addToScope) {
    if (this.item.materials.filter(x => x.id == this.material.id).length > 0) {
      alert("Material already in the order");
      return;
    }
    let item = this.material;
    item.quantity = this.quantity;
    item.unitPrice = this.price;
    item.total = this.quantity * this.price;
    this.item.materials.push(item);
    this.expended += item.total;
    this.remaining = this.total - this.expended;

    this.calculateTotalOrder();

    if (addToScope)
      this.addMaterialToScope();
  }

  async addMaterialToScope() {
    let index = this.selectedProject.scopes.map(x => { return x.id }).indexOf(this.scope.id);
    this.material.itemName = this.material.name;
    this.selectedProject.scopes[index].materials.push(this.material);
    await this.db.collection("Projects").doc(this.selectedProject.id).set(this.selectedProject);
  }

  async newMaterialToScope() {
    if (this.materialItem.name.length == 0 || this.materialItem.description.length == 0 || this.materialItem.price == 0
      || this.materialItem.size.length == 0 || this.materialItem.unit.length == 0) {
      alert("Complete all data");
      return;
    }
    if (this.photo != undefined) {
      let result: any = await this.uploadImagesService.uploadImage("materials", this.materialItem.photo.name, this.file);
      result.subscribe(async url => {
        this.materialItem.photo.url = url;
        this.materialItem.name = this.materialItem.name.trim().toLocaleUpperCase();
        let newMaterial = await (await this.db.collection("Materials").add(this.materialItem)).get();
        let savedMaterial = newMaterial.data();
        savedMaterial.id = newMaterial.id;
        savedMaterial.itemName = savedMaterial.name;
        let index = this.selectedProject.scopes.map(x => { return x.id }).indexOf(this.scope.id);
        this.selectedProject.scopes[index].materials.push(savedMaterial);
        await this.db.collection("Projects").doc(this.selectedProject.id).set(this.selectedProject);
        this.material = savedMaterial;
        this.price = savedMaterial.price;
        this.addMaterial(false);
        this.updateMaterials();
        document.getElementById("close-modal-newMaterial").click();
      })
    }
    else {
      this.materialItem.name = this.materialItem.name.trim().toLocaleUpperCase();
      let newMaterial = await (await this.db.collection("Materials").add(this.materialItem)).get();
      let savedMaterial = newMaterial.data();
      savedMaterial.id = newMaterial.id;
      savedMaterial.itemName = savedMaterial.name;
      let index = this.selectedProject.scopes.map(x => { return x.id }).indexOf(this.scope.id);
      this.selectedProject.scopes[index].materials.push(savedMaterial);
      await this.db.collection("Projects").doc(this.selectedProject.id).set(this.selectedProject);
      this.material = savedMaterial;
      this.price = savedMaterial.price;
      this.addMaterial(false);
      this.updateMaterials();
      document.getElementById("close-modal-newMaterial").click();
    }

  }

  removeMaterial(id) {
    if (!confirm("Are you sure of remove this item?")) return;
    let index = this.item.materials.map(x => { return x.id }).indexOf(id);
    this.expended -= this.item.materials[index].total;
    this.remaining = this.total - this.expended;
    this.item.materials.splice(index, 1);
    this.calculateTotalOrder();
  }

  async saveData() {
    if (!this.validate()) {
      alert("Complete all data");
      return;
    }
    if (!confirm("Are you sure of save this item?")) return;
    this.calculateTotalOrder();
    this.item.purchaseOrderNumber = await this.getPurchaseOrder();
    await this.db.collection("Orders").add(this.item);
    alert("Data saved successfully");
    this.router.navigate(['/app/orders']);
  }

  keyword = 'name';

  calculateTotalOrder() {
    this.item.total = 0;
    for (let i = 0; i < this.item.materials.length; i++) {
      this.item.total += this.item.materials[i].total;
    }
  }


  onChangeSearch(val: string) {
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
  }

  onFocused(e) {
    // do something when input is focused
  }

  async sendToEmail() {

    if (!confirm("Are you sure of save this item?")) return;
    this.calculateTotalOrder();
    await this.exportToPDF(true)
    await this.db.collection("Orders").add(this.item);
    alert("Data saved successfully");
    this.router.navigate(['/app/orders']);
    let supplier = await (await this.db.collection("Suppliers").doc(this.item.supplierKey).ref.get()).data();
    console.log(supplier);
    const callable = this.functions.httpsCallable('sendOrderEmailWithPDF');
    let mailData = { emails: supplier.email, materials: this.item.materials, total: this.item.total, file: btoa(this.orderPdf) };
    console.log(mailData);
    const obs = callable(mailData);
    let res = await obs.toPromise().catch(error => console.log(error));;
    this.db.collection("OrdersEmails").add({ emails: supplier.email, materials: this.item.materials, total: this.item.total });
  }

  async getPurchaseOrder() {
    let purchaseOrderNumber = 1;
    let orders = await (await this.db.collection("Orders").ref.orderBy("purchaseOrderNumber", "desc").limit(1).get()).docs;
    if (orders.length > 0) {
      purchaseOrderNumber = orders[0].data().purchaseOrderNumber + 1;
    }
    return purchaseOrderNumber;
  }

  async exportToPDF(sendToEmail) {
    if (!this.validate()) {
      alert("Complete all data");
      return;
    }
    const doc = new jsPDF();
    let orderDate = moment(this.item.date).format("DD/MM/YYYY");
    let purchaseOrderNumber = await this.getPurchaseOrder();

    doc.addImage("assets/img/logoC.png", "PNG", 10, 10, 50, 18);
    doc.setTextColor(255, 0, 0);
    doc.setFontSize(20);
    doc.text('Soluction Construction', 10, 40);
    doc.setFont("helvetica", "bold");
    doc.text('Purchase Order', 140, 20);
    doc.setFontSize(14);
    doc.setTextColor(0, 0, 0);
    doc.text(`DATE: ${orderDate}`, 145, 30);
    doc.text(`PO: ${purchaseOrderNumber}`, 145, 35);
    doc.setFont("helvetica", "normal");
    doc.text('8160 NW 93 st', 10, 50);
    doc.text('Medley FL, 33166', 10, 55);
    doc.text('Phone: (786) 621-8550', 10, 60);

    doc.setDrawColor(0);
    doc.setFillColor(255, 0, 0);
    doc.rect(10, 65, 85, 10, "F");
    doc.rect(120, 65, 85, 10, "F");
    doc.setTextColor(255, 255, 255);
    doc.setFont("helvetica", "bold");
    doc.text('Vendor', 11, 72);
    doc.text('Project', 121, 72);
    doc.setFont("helvetica", "normal");
    doc.text(this.item.supplier, 30, 72);
    doc.text(this.item.projectName, 140, 72);
    doc.setTextColor(0, 0, 0);
    doc.text('Project Address:', 121, 80);
    doc.text(this.selectedProject.address != undefined ? this.selectedProject.address : "", 121, 85);//TODO

    doc.setDrawColor(0);
    doc.setFillColor(255, 0, 0);
    doc.rect(10, 100, 190, 10, "FD");
    doc.rect(10, 120, 50, 10, "FD");
    //table header
    doc.rect(10, 130, 190, 10, "FD");
    doc.setTextColor(255, 255, 255);

    doc.text("DESCRIPTION", 11, 137);
    doc.text("QTY", 100, 137);
    doc.text("UNIT PRICE", 130, 137);
    doc.text("TOTAL", 170, 137);

    doc.setFillColor(255, 255, 255);
    doc.rect(10, 110, 190, 10, "FD");

    doc.rect(10, 110, 190, 10, "FD");


    doc.text("Order Prepared by", 11, 107);
    doc.text("SHIP VIA", 100, 107);
    doc.setTextColor(0, 0, 0);
    doc.text(this.userName, 11, 117);
    doc.text("Pick Up ", 100, 117);
    doc.setTextColor(255, 255, 255);
    doc.text(`SCOPE: ${this.item.scope}`, 11, 127);
    doc.setTextColor(0, 0, 0);

    let yLineCell = 140;
    let yLineText = 147;
    //material table
    doc.setFontSize(11);
    for (let i = 0; i < this.item.materials.length; i++) {
      doc.setDrawColor(0);
      doc.setFillColor(255, 255, 255);
      doc.rect(10, yLineCell, 90, 10, "FD");
      doc.rect(100, yLineCell, 30, 10, "FD");
      doc.rect(130, yLineCell, 40, 10, "FD");
      doc.setDrawColor(0);
      doc.setFillColor(255, 0, 0);
      doc.rect(170, yLineCell, 30, 10, "FD");
      doc.setTextColor(0, 0, 0);
      doc.text(this.item.materials[i].name, 11, yLineText);
      doc.text(this.item.materials[i].quantity.toString(), 101, yLineText);
      doc.text(this.currency.transform(this.item.materials[i].unitPrice.toString()), 131, yLineText);
      doc.text(this.currency.transform(this.item.materials[i].total.toString()), 171, yLineText);

      yLineCell = yLineCell + 10;
      yLineText = yLineText + 10;

      if (yLineText >= 257) {
        doc.addPage();
        yLineCell = 10;
        yLineText = 17;
      }
    }

    doc.setDrawColor(0);
    doc.setFillColor(255, 0, 0);
    doc.rect(10, yLineCell, 90, 10, "FD");
    doc.text("Comments or Special Instructions", 11, yLineCell + 7);
    yLineCell += 10;
    doc.setDrawColor(0);
    doc.setFillColor(255, 255, 255);
    doc.rect(10, yLineCell, 90, 30, "FD");
    doc.line(170, yLineCell + 1, 200, yLineCell + 1);
    doc.setFontSize(14);
    doc.text(this.currency.transform(this.item.total), 171, yLineCell);
    yLineCell += 7;
    doc.text("SUBTOTAL", 171, yLineCell);
    doc.setFontSize(9);
    doc.text(this.item.comments, 11, yLineCell);

    yLineCell += 10;
    doc.line(170, yLineCell + 1, 200, yLineCell + 1);
    doc.setFontSize(14);
    doc.text(this.currency.transform((this.item.total * (this.item.taxPercent / 100))), 171, yLineCell);
    yLineCell += 7;

    doc.text("TAXES", 171, yLineCell);
    doc.setFontSize(14);
    yLineCell += 10;
    doc.line(170, yLineCell + 1, 200, yLineCell + 1);
    doc.text(this.currency.transform(this.item.total + (this.item.total * (this.item.taxPercent / 100))), 171, yLineCell);
    doc.setFontSize(14);
    yLineCell += 7;
    doc.text("TOTAL", 171, yLineCell);

    if (sendToEmail)
      this.orderPdf = doc.output();
    else {
      doc.save(`order-${orderDate}${purchaseOrderNumber}.pdf`);
      this.item.purchaseOrderNumber = await this.getPurchaseOrder();
      await this.db.collection("Orders").add(this.item);
      alert("Data saved successfully");
      this.router.navigate(['/app/orders']);
    }
  }

  createHeaders(keys) {
    var result = [];
    for (var i = 0; i < keys.length; i += 1) {
      result.push({
        id: keys[i],
        name: keys[i],
        prompt: keys[i],
        width: 150,
        align: "left"
      });
    }
    return result;
  }

  validate() {
    if (this.item.materials.length == 0) {
      return false;
    }

    return true;
  }

  cancel() {
    this.router.navigate(['/app/orders']);
  }

  async uploadTeacherImage(event) {
    if ((event.target.files[0].size / 1024) > 1000) {
      alert("File is to large, max file size is 1MB");
      this.photo = "";
      return;
    }
    this.materialItem.photo.url = URL.createObjectURL(event.target.files[0]);
    this.urlSafe = this.sanitizer.bypassSecurityTrustUrl(this.materialItem.photo.url);
    this.materialItem.photo.name = event.target.files[0].name + new Date().toDateString() + new Date().toLocaleTimeString()
    this.file = event.target.files[0];
  }
}
