import { Observable } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { Router } from '@angular/router';
import { UploadImagesService } from 'src/app/services/storage/upload-images.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
@Component({
  selector: 'app-create-material',
  templateUrl: './create-material.component.html',
  styleUrls: ['./create-material.component.css']
})
export class CreateMaterialComponent implements OnInit {

  item = {
    name: "",
    description: "",
    price: 0,
    unit: "",
    size: "",
    photo: {
      url: "https://firebasestorage.googleapis.com/v0/b/constructora-56ce3.appspot.com/o/placeholder.jpeg?alt=media&token=53324723-0e82-41d8-b015-0d04cd815db0",
      name: "placeholder"
    }
  }
  photo;
  urlSafe: SafeResourceUrl;
  file: File;

  constructor(private db: AngularFirestore,
    private router: Router,
    private uploadImagesService: UploadImagesService,
    private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.urlSafe = this.sanitizer.bypassSecurityTrustUrl(this.item.photo.url);
  }

  async saveData() {
    if (!this.validate()) {
      alert("Complete all data");
      return;
    }

    let materials = await (await this.db.collection("Materials").ref.where("name", "==", this.item.name.trim().toUpperCase()).get()).docs;
    if (materials.length > 0) {
      alert("Material already exist in the Data Base");
      return;
    }

    if (!confirm("Are you sure of save this item?")) return;

    if (this.photo != undefined) {
      let result: any = await this.uploadImagesService.uploadImage("materials", this.item.photo.name, this.file);
      result.subscribe(async url => {
        this.item.photo.url = url;
        this.item.name = this.item.name.trim().toLocaleUpperCase();
        await this.db.collection("Materials").add(this.item);
        alert("Data saved successfully");
        this.router.navigate(['/app/materials']);
      });
    }
    else {
      this.item.name = this.item.name.trim().toLocaleUpperCase();
      await this.db.collection("Materials").add(this.item);
      alert("Data saved successfully");
      this.router.navigate(['/app/materials']);
    }
  }

  validate() {
    if (this.item.name.length == 0
      || this.item.description.length == 0
      || this.item.unit.length == 0
      || this.item.size.length == 0)
      return false;

    return true;
  }

  async uploadTeacherImage(event) {
    if ((event.target.files[0].size / 1024) > 1000) {
      alert("File is to large, max file size is 1MB");
      this.photo = "";
      return;
    }
    this.item.photo.url = URL.createObjectURL(event.target.files[0]);
    this.urlSafe = this.sanitizer.bypassSecurityTrustUrl(this.item.photo.url);
    this.item.photo.name = event.target.files[0].name + new Date().toDateString() + new Date().toLocaleTimeString()
    this.file = event.target.files[0];
  }

}
