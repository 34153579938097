import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRoute } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import * as XLSX from 'xlsx';
import * as moment from 'moment';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'app-project-orders',
  templateUrl: './project-orders.component.html',
  styleUrls: ['./project-orders.component.css']
})
export class ProjectOrdersComponent implements OnInit {

  orderList: any = [];
  scopeList: any = [];
  selectedScope: any = "all";
  filterScopeList: any = [];
  dtOptions: any = {};
  dtTrigger: Subject<DataTables.DataTables> = new Subject();
  @ViewChild(DataTableDirective, { static: false }) datatableElement: DataTableDirective;

  docKey = "";

  item: any;

  total = 0;
  spent = 0;
  remaining = 0;

  constructor(private db: AngularFirestore,
    private actRoute: ActivatedRoute,
    private currency: CurrencyPipe,) { }

  ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      // Declare the use of the extension in the dom parameter
      dom: 'Bfrtip',
      // Configure the buttons
      buttons: [
        'print',
        'excel',
      ],
      pageLength: 100, //language: GlobalConstants.espanol
    };
    this.loadData();
  }

  async loadData() {


    this.docKey = this.actRoute.snapshot.paramMap.get('id');

    this.orderList = await (await this.db.collection("Orders").ref.where("projectKey", "==", this.docKey).get()).docs;

    this.filterScopeList = this.orderList.slice();
    console.log(this.filterScopeList);
    this.dtTrigger.next();

    this.load(this.docKey).then(result => {
      this.item = result;
      this.scopeList = result.scopes;

      this.calculateTotals();
    });


  }

  load(docKey) {
    return new Promise<any>((resolve, reject) => {
      this.db.collection('Projects').doc(docKey).valueChanges()
        .subscribe(snapshots => {
          resolve(snapshots)
        })
    })
  }

  onSelectScope(data) {
    console.log(data);
    // console.log(this.selectedScope);
    this.calculateTotals();
  }

  calculateTotals() {
    this.spent = 0;
    this.total = 0;
    this.filterScopeList = [];
    if (this.selectedScope == "all") {
      this.total = this.item.budget;
      this.orderList.forEach(element => {
        this.spent += element.data().total;
      });
      this.filterScopeList = this.orderList.slice();
    }
    else {
      this.total = this.item.scopes.filter(item => item.id == this.selectedScope)[0].budget;
      this.orderList.forEach(element => {
        console.log(element.id);
        if (element.data().scopeKey == this.selectedScope) {
          this.filterScopeList.push(element);
          this.spent += element.data().total;
        }

      });
    }

    this.remaining = this.total - this.spent;

    this.rerender();
  }

  exportexcel() {

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_aoa(ws, [["Project: " + this.item.name]], { origin: 0 });
    XLSX.utils.sheet_add_aoa(ws, [["Start Date: " + moment(this.item.initDate).format("YYYY-MM-DD")]], { origin: 1 });
    XLSX.utils.sheet_add_aoa(ws, [["Current Date: " + moment().format("YYYY-MM-DD")]], { origin: 2 });

    XLSX.utils.sheet_add_aoa(ws, [["Total: " + this.currency.transform(this.total + (this.total * (this.item.taxPercent / 100))),
    "Spent: " + this.currency.transform(this.spent),
    "Taxes: " + this.currency.transform(this.spent * (this.item.taxPercent / 100)),
    "Remaining: " + this.currency.transform(this.remaining + (this.remaining * (this.item.taxPercent / 100)))]],
      { origin: 3 });

    XLSX.utils.sheet_add_aoa(ws, [[""]], { origin: 4 });

    let row = 5;
    for (let i = 0; i < this.scopeList.length; i++) {

      XLSX.utils.sheet_add_aoa(ws, [["Scope", "Total", "Spent", "Tax", "Remaining"]], { origin: row });
      let totalScope = this.getTotalScope(this.scopeList[i].id);
      totalScope = +(totalScope + (totalScope * (this.item.taxPercent / 100)));
      let spentScope = this.getSpentScope(this.scopeList[i].id);
      let spentScopeTax = spentScope * (this.item.taxPercent / 100);
      row++;
      XLSX.utils.sheet_add_aoa(ws, [[this.scopeList[i].name, " " + this.currency.transform(totalScope), " " + this.currency.transform(spentScope), " " + this.currency.transform(spentScopeTax), " " + this.currency.transform((totalScope - (spentScope + spentScopeTax)))]], { origin: row });
      row++;
      XLSX.utils.sheet_add_aoa(ws, [[""]], { origin: row });

      let filterScopes = this.filterScopeList.filter(x => x.data().scopeKey == this.scopeList[i].id);

      for (let j = 0; j < filterScopes.length; j++) {
        if (filterScopes[j].data().materials.length > 0) {
          row++;
          XLSX.utils.sheet_add_aoa(ws, [["Date", "Material", "Quantity", "Price", "Total"]], { origin: row });
        }

        for (let k = 0; k < filterScopes[j].data().materials.length; k++) {
          row++;
          XLSX.utils.sheet_add_aoa(ws, [[
            moment(filterScopes[j].data().date).format("DD/MM/YYYY"),
            filterScopes[j].data().materials[k].name,
            + filterScopes[j].data().materials[k].quantity,
            "$ " + filterScopes[j].data().materials[k].unitPrice,
            "$ " + filterScopes[j].data().materials[k].total,]], { origin: row });
        }
      }

      row++;
      XLSX.utils.sheet_add_aoa(ws, [[""]], { origin: row });

      row++;
      XLSX.utils.sheet_add_aoa(ws, [[""]], { origin: row });

    }

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    XLSX.writeFile(wb, "Project " + this.item.name + ".xlsx");


  }

  getTotalScope(scopeKey) {
    return this.item.scopes.filter(x => x.id == scopeKey)[0].budget;
  }

  getSpentScope(scopeKey) {
    let spentScope = 0;
    this.filterScopeList.forEach(element => {
      if (element.data().scopeKey == scopeKey)
        spentScope += element.data().total;
    });
    return spentScope;
  }

  rerender(): void {
    if (this.datatableElement.dtInstance == undefined) { this.dtTrigger.next(); return; }
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

}
