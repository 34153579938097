import { AngularFirestore } from '@angular/fire/firestore';
import { Component, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { UploadImagesService } from 'src/app/services/storage/upload-images.service';

@Component({
  selector: 'app-list-materials',
  templateUrl: './list-materials.component.html',
  styleUrls: ['./list-materials.component.css']
})
export class ListMaterialsComponent implements OnInit {

  materialList: any = [];
  dtOptions: any = {};
  dtTrigger: Subject<DataTables.DataTables> = new Subject();
  @ViewChild(DataTableDirective, { static: false }) datatableElement: DataTableDirective;

  constructor(private db: AngularFirestore,
    private uploadImagesService: UploadImagesService,) { }

  ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      // Declare the use of the extension in the dom parameter
      dom: 'Bfrtip',
      // Configure the buttons
      buttons: [
        'print',
        'excel',
      ],
      pageLength: 8, //language: GlobalConstants.espanol
    };
    this.loadData();

  }

  async loadData() {
    this.materialList = await (await this.db.collection("Materials").ref.get()).docs;
    this.dtTrigger.next();
  }

  delete(docKey, imageName) {
    console.log(imageName)
    if (!confirm("Are your sure of delete this item?")) return;
    var index = index = this.materialList.map(x => { return x.id }).indexOf(docKey);
    this.deleteItem(docKey)
      .then(
        async res => {
          if (imageName != "placeholder" && imageName != undefined) {
            let deletedItem = await this.uploadImagesService.deleteFile("materials", imageName);
            deletedItem.subscribe(async item => {
              //deleted item
              this.materialList.splice(index, 1);
              this.rerender();
            });
          }
          else
            this.materialList.splice(index, 1);
        },
        err => {
          console.log(err);
        }
      )
  }

  deleteItem(docKey) {
    return this.db.collection('Materials').doc(docKey).delete();
  }

  rerender(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

}
