import { Observable } from "rxjs";

export class GlobalConstants {
  //Usuario actual
  public static user: any = undefined;

  public static showSidebar: boolean = true;

  public static espanol: any = {
    "sProcessing": "Procesando...",
    "sLengthMenu": "Mostrar registros",
    "sZeroRecords": "No se encontraron resultados",
    "sEmptyTable": "Ningún dato disponible en esta tabla",
    "sInfo": "",
    "sInfoEmpty": "",
    "sInfoFiltered": "(filtrado de registros)",
    "sInfoPostFix": "",
    "sSearch": "Buscar:",
    "sUrl": "",
    "sInfoThousands": ",",
    "sLoadingRecords": "Cargando...",
    "oPaginate": {
      "sFirst": "Primero",
      "sLast": "Último",
      "sNext": "Siguiente",
      "sPrevious": "Anterior"
    },
    "oAria": {
      "sSortAscending": ": Activar para ordenar la columna de manera ascendente",
      "sSortDescending": ": Activar para ordenar la columna de manera descendente"
    },
    "buttons": {
      "copy": "Copiar",
      "colvis": "Visibilidad",
      "print": "Ver estado de cuenta",
      "excel": "Descargar estado de cuenta"
    }
  }
}
