import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService,
    private router: Router) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let url: string = state.url;
    return this.checkUserLogin(next, url);
  }

  checkUserLogin(route: ActivatedRouteSnapshot, url: any): boolean {
    if (this.authService.isAuthenticated()) {
      const userRole: string[] = this.authService.getRole();
      for (let i = 0; i < userRole.length; i++) {

        //console.log(route.data.role.indexOf(userRole[i]));
        if (route.data.role && route.data.role.indexOf(userRole[i]) != -1) {
          return true;
        }
      }
      this.router.navigate(['/app/home']);
      return false;
    }

    this.router.navigate(['/login']);
    return false;
  }


}
