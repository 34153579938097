import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-list-scopes',
  templateUrl: './list-scopes.component.html',
  styleUrls: ['./list-scopes.component.css']
})
export class ListScopesComponent implements OnInit {

  scopeList: any = [];
  dtOptions: any = {};
  dtTrigger: Subject<DataTables.DataTables> = new Subject();
  @ViewChild(DataTableDirective, { static: false }) datatableElement: DataTableDirective;

  constructor(private db: AngularFirestore) { }

  ngOnInit() {
    this.loadData();
  }

  async loadData() {
    this.scopeList = await (await this.db.collection("Scopes").ref.get()).docs;
    this.dtTrigger.next();
  }

  delete(docKey) {
    if (!confirm("Are you sure of delete this item?")) return;
    var index = index = this.scopeList.map(x => { return x.id }).indexOf(docKey);
    this.deleteItem(docKey)
      .then(
        res => {
          this.scopeList.splice(index, 1);
          this.rerender();
        },
        err => {
          console.log(err);
        }
      )
  }

  deleteItem(docKey) {
    return this.db.collection('Scopes').doc(docKey).delete();
  }

  rerender(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

}
