import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-update-scopes',
  templateUrl: './update-scopes.component.html',
  styleUrls: ['./update-scopes.component.css']
})
export class UpdateScopesComponent implements OnInit {

  item = {
    name: "",
    description: "",
  }

  docKey = "";

  constructor(private router: Router,
    public db: AngularFirestore, private actRoute: ActivatedRoute,) { }

  ngOnInit() {
    this.docKey = this.actRoute.snapshot.paramMap.get('id');
    this.load(this.docKey).then(result => {
      this.item = result;
    });
  }

  load(docKey) {
    return new Promise<any>((resolve, reject) => {
      this.db.collection('Scopes').doc(docKey).valueChanges()
        .subscribe(snapshots => {
          resolve(snapshots)
        })
    })
  }

  updateData() {
    if (!confirm("Are you sure of save this item?")) return;
    this.db.collection('Scopes').doc(this.docKey).set(this.item);
    alert("Data saved successfully");
    this.router.navigate(['/app/scopes']);
  }

}
