import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.css']
})
export class CreateUserComponent implements OnInit {

  item = {
    user: "",
    email: "",
    phone: "",
    password: "",
    type: []
  }

  admin = false;
  dbAcces = false;
  projects = false;

  fieldTextType: boolean;

  constructor(private db: AngularFirestore, private router: Router,) { }

  ngOnInit() {
  }

  async saveData() {
    if (this.admin) this.item.type.push("admin")
    if (this.dbAcces) this.item.type.push("dbAcces")
    if (this.projects) this.item.type.push("projects")

    if (!this.validate()) {
      alert("Complete all data");
      return;
    }

    if (!confirm("Are you sure of save this item?")) return;

    await this.db.collection("User").add(this.item);
    alert("Data saved successfully");
    this.router.navigate(['/app/users']);
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  validate() {
    console.log(this.item)
    if (this.item.user.length == 0
      || this.item.email.length == 0
      || this.item.phone.length == 0
      || this.item.password.length == 0
      || this.item.type.length == 0) {
      return false;
    }

    return true;
  }


}
