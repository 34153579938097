import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-update-supplier',
  templateUrl: './update-supplier.component.html',
  styleUrls: ['./update-supplier.component.css']
})
export class UpdateSupplierComponent implements OnInit {

  item = {
    name: "",
    address: "",
    phone: "",
    email: ["", "", "", "", ""]
  }

  docKey = "";


  constructor(private router: Router,
    public db: AngularFirestore, private actRoute: ActivatedRoute,) { }

  ngOnInit() {
    this.docKey = this.actRoute.snapshot.paramMap.get('id');
    this.load(this.docKey).then(result => {
      this.item = result;
    });
  }

  load(docKey) {
    return new Promise<any>((resolve, reject) => {
      this.db.collection('Suppliers').doc(docKey).valueChanges()
        .subscribe(snapshots => {
          resolve(snapshots)
        })
    })
  }

  updateData() {
    if (!this.validate()) {
      alert("Complete all data");
      return;
    }
    if (!confirm("Are you sure of save this item?")) return;
    this.item.email = this.item.email.map(x => { return x.trim() });//Clean mails
    this.item.name = this.item.name.trim().toUpperCase();
    this.db.collection('Suppliers').doc(this.docKey).set(this.item);
    alert("Data saved successfully");
    this.router.navigate(['/app/vendors']);
  }

  validate() {
    if (this.item.name.length == 0
      || this.item.address.length == 0
      || this.item.phone.length == 0) {
      return false;
    }

    if (this.item.email[0].length > 0
      || this.item.email[1].length > 0
      || this.item.email[3].length > 0
      || this.item.email[4].length > 0)
      return true;
    else
      return false;
  }

}
