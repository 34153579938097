import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

@Pipe({
  name: 'customCurrency'
})
export class CustomCurrencyPipe extends CurrencyPipe implements PipeTransform {
  transform(value: any, currencyCode: string = 'USD', display: string | boolean = 'symbol', digitsInfo?: string, locale?: string): string | null {
    //if(value === null || value === undefined) return null;
    return super.transform(value, currencyCode, display, digitsInfo, locale).replace('$', 'L');
  }
}
