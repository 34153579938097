import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';

@Component({
  selector: 'app-create-supplier',
  templateUrl: './create-supplier.component.html',
  styleUrls: ['./create-supplier.component.css']
})
export class CreateSupplierComponent implements OnInit {

  item = {
    name: "",
    address: "",
    phone: "",
    email: ["", "", "", "", ""]
  }

  constructor(private db: AngularFirestore, private router: Router,) { }

  ngOnInit() {

  }

  async saveData() {
    if (!this.validate()) {
      alert("Complete all data");
      return;
    }
    if (!confirm("Are you sure of save this item?")) return;
    this.item.email = this.item.email.map(x => { return x.trim() });//Clean mails
    this.item.name = this.item.name.trim().toUpperCase();
    await this.db.collection("Suppliers").add(this.item);
    alert("Data saved successfully");
    this.router.navigate(['/app/vendors']);
  }

  validate() {
    if (this.item.name.length == 0
      || this.item.address.length == 0
      || this.item.phone.length == 0) {
      return false;
    }

    if (this.item.email[0].length > 0
      || this.item.email[1].length > 0
      || this.item.email[3].length > 0
      || this.item.email[4].length > 0)
      return true;
    else
      return false;
  }

}
